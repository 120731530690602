import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button";
import OrderedList from "~/components/List/Ordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={2}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 2</H2>

          <p>The 3 websites linked below all focus on CBD.</p>
          <p>Remember, the question you want to answer is, "Is CBD use to relieve chronic pain supported by science and medicine?"</p>
          <p>Work with your teammates to scan each site. At this point, do not try to read everything. Just think about your first impressions of each site. You should be able to do this in about 5 minutes. After you have looked at the sites, discuss the following questions:</p>

          <OrderedList>
            <li>Do you think the 3 websites are equal in quality, or do you think any are better than the others for answering your question?</li>
            <li>What features make you think that one site is better than others?</li>
            <li>Are there specific things on any of the websites that make you question the information on the site? What are those features?</li>
            <li>Which website would you recommend for other people to look at? Why?</li>
          </OrderedList>

          <p>Write your answers in your science notebook.</p>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="purple"
              href="/article/cbd/cbd-now"
              icon="external"
              title="CBD website 1"
            >
              CBD website 1
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="purple"
              href="/article/cbd/cbd-truth"
              icon="external"
              title="CBD website 2"
            >
              CBD website 2
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="purple"
              href="/article/cbd/cansativa-organics"
              icon="external"
              title="CBD website 3"
            >
              CBD website 3
            </Button>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
